import { Container } from "react-bootstrap";
import ContentWithVideo from "../../../../sections/ContentWithVideo/ContentWithVideo";
import Stack from "../../../../../components/arrangement/Stack/Stack";
import Group from "../../../../../components/arrangement/Group/Group";
import Positioner from "../../../../../components/arrangement/Positioner/Positioner";

import { blockchainPath, dppPath, rwaPath, skeyBoxPath } from "../../../../../data/menuLinks";
import { useIsMobile } from "../../../../../hooks/useIsMobile";
import useBoolean from "../../../../../hooks/useBoolean";

import "./Solutions.scss";

const items = [
  {
    label: "RWA",
    url: rwaPath
  },
  {
    label: "Digital Product Passport",
    url: dppPath
  },
  {
    label: "NFT",
    url: skeyBoxPath
  },
  {
    label: "Internet of Things",
    url: blockchainPath
  }
];

const Content = () => {
  const isMobile = useIsMobile("lg");
  return (
    <Stack rowGap={32} className="pl-xs-0 pl-md-2" fullWidth style={{ maxWidth: isMobile ? "unset" : "290px" }}>
      <p className="s1 dark t-xs-c t-lg-l" style={{ fontSize: "18px" }}>
        Skey Network brings real value to the blockchain world. Check out our successful solutions!
      </p>
      <Stack fullWidth rowGap={5}>
        {items.map((it, id) => (
          <a href={it.url} target="_self" className="pt-xs-1 pb-xs-1 w-xs-100 w-lg-mc" key={id}>
            <Group fullSize colGap={8} justifyContent="center" alignItems="center">
              <p className="primary s2">{it.label}</p>
              <img src="/images/go-to-30.svg" alt={it.label} width={"24px"} height={"24px"} />
            </Group>
          </a>
        ))}
      </Stack>
    </Stack>
  );
};

const Video = () => {
  const [canPlay, enable] = useBoolean(false);

  return (
    <>
      {canPlay ? (
        <video playsInline className="video" controls autoPlay>
          <source src="/videos/overview/RWA-and-DPP.mp4" type="video/mp4" />
        </video>
      ) : (
        <button onClick={enable} className="relative video-placeholder video">
          <img src="/images/overview/solutions-video-placeholder.jpg" alt="placeholder" className="video" />
          <Positioner xCoord={"center"} yCoord={"center"}>
            <div className="relative">
              <img src="images/icons/play-button-bg.svg" alt="play button bg" className="play-button-bg" />
              <Positioner xCoord="center" yCoord="center">
                <img src="images/icons/play-icon.svg" alt="play button icon" className="play-button-icon" />
              </Positioner>
            </div>
          </Positioner>
        </button>
      )}
    </>
  );
};

const Solutions = () => {
  return (
    <div className="solutions">
      <Container fluid="xxl" className="o-x-hidden">
        <Container fluid="xl">
          <ContentWithVideo content={<Content />} ownVideoTag={<Video />} />
        </Container>
      </Container>
    </div>
  );
};

export default Solutions;
